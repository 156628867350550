<template>
  <div class="home">
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>{{headline}}</h1>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row row-split-content">
          <div class="content-width-small" style="text-align: center;">
            <h6 class="subheading text-primary-1 today">Today is {{ today }}!</h6>
            <h3 class="h1">{{postTitle}}</h3>
            <div style="text-align: justify;">{{postDescription}}</div>
            <a href="#" class="button w-inline-block today-download">
              <div>
                <a v-bind:href="download" class="button w-inline-block post-download" download>
                  <div>
                    Download
                    <i class="fas fa-download"></i>
                  </div>
                </a>
              </div>
            </a>
          </div>
          <div class="content-width-large">
            <div>
              <div class="w-slider-mask">
                <div class="w-slide">
                  <img v-bind:src="postImage" width="512.5" />
                </div>
              </div>
              <div class="display-none w-slider-nav w-round"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="row row-split-content">
          <div class="content-width-medium">
            <img v-bind:src="mollyImage" alt />
          </div>
          <div class="content-width-small">
            <h1>{{ctaHeader}}</h1>
            <div class="text-large">{{ctaDescription}}</div>
            <div class="form-wrapper margin-top w-form">
              <a href="/checkout" class="button w-button">{{ctaButtonText}}</a>
            </div>
            <div class="handwriting-wrapper hidden-on-mobile">
              <div class="handwriting-contents handwriting-below">
                <div class="handwriting text-primary-2 rotate">{{orangeText}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageService from "@/services/pageService.js";
import moment from "moment";
export default {
  name: "home",
  data: () => ({
    headline: null,
    ctaText: null,
    ctaDescription: null,
    ctaButtonText: null,
    ctaHeader: null,
    orangeText: null,
    mollyImage: null,
    postTitle: null,
    postDescription: null,
    postImage: null,
    today: moment(Date.now()).format("MMMM Do")
  }),
  computed: {
    download: function() {
      var media = this.postImage;
      if (media) {
        media = media.substring(26);
        var safeTitle = encodeURI(this.postTitle + ".jpg");
        var url =
          "https://fs.buttercms.com/content=t:attachment,f:%22" +
          safeTitle +
          "%22/" +
          media;
        return url;
      }
      return "";
    }
  },
  created() {
    PageService.getPage("free-day")
      .then(response => {
        var content = response.data.data.fields;
        console.log(content);
        this.headline = content.headline;
        this.ctaButtonText = content.cta_button_text;
        this.ctaDescription = content.cta_description;
        this.ctaText = content.cta_text;
        this.ctaHeader = content.cta_header;
        this.orangeText = content.orange_text;
        this.mollyImage = content.molly_image;
        this.postTitle = content.post_title;
        this.postDescription = content.post_description;
        this.postImage = content.post_image;
      })
      .catch(error => {
        console.log("there was an error getting the page", error);
      });
  }
};
</script>
